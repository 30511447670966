<template>
  <!-- TOP Nav Bar -->
    <div class="iq-top-navbar  top-menu">
      <div class="container-fluid p-0">
      <div class="iq-navbar-custom">
        <nav class="navbar navbar-expand-lg navbar-light p-0" style="height: 75px">
            <div class="iq-navbar-logo d-flex justify-content-between">
              <router-link to="/">
                <img :src="logo" class="img-fluid" alt="logo">
              </router-link>
              <div class="iq-menu-bt align-self-center lg-invisible">
                <div class="wrapper-menu" @click="miniSidebar">
                    <div class="main-circle"><i class="ri-menu-line"></i></div>
                </div>
              </div>
            </div>
            <b-navbar-toggle target="nav-collapse" class="mr-2">
              <i class="ri-menu-3-line"></i>
            </b-navbar-toggle>
             <b-collapse id="nav-collapse" is-nav>
              <ul class="navbar-nav navbar-list">  
                    <li>
                        <router-link to="/organization-chart" class="iq-waves-effect d-flex align-items-center">
                          <div class="caption">
                              <h6 class="mb-0 line-height">Team</h6>
                          </div>
                        </router-link>
                    </li>           
                  <!-- <li class="dropdown">
                      <a href="#" class="iq-waves-effect d-flex align-items-center search-toggle">
                        <div class="caption">
                        <h6 class="mb-0 line-height">Team<i class="ri-arrow-down-s-line iq-arrow-down"></i></h6>
                        </div> </a>
                    <div class="iq-sub-dropdown">
                  <div class="iq-card shadow-none m-0">
                    <div class="iq-card-body sm-p-0 ">
                     <router-link to="/organization-chart"><a class="iq-waves-effect d-flex align-items-center">
                              <div class="caption">
                                 <h6 class="mb-0 line-height sm-pl-1">Organization Chart</h6>
                              </div>
                           </a></router-link>
                      <router-link to='/team'><a class="iq-waves-effect d-flex align-items-center">
                              <div class="caption">
                                 <h6 class="mb-0 line-height sm-pl-1">Team Map</h6>
                              </div>
                           </a></router-link> </div>
                      </div>
                       </div>
                          

                           
                        </li> -->
                    <li>
                           <router-link to="/policies" class="iq-waves-effect d-flex align-items-center">
                              <div class="caption">
                                 <h6 class="mb-0 line-height">Policies</h6>
                              </div>
                           </router-link>
                        </li>
                        <li>
                           <router-link to="/picture" class="iq-waves-effect d-flex align-items-center">
                              <div class="caption">
                                 <h6 class="mb-0 line-height">Picture Album</h6>
                              </div>
                           </router-link>
                        </li>  <li>
                           <router-link to="/music" class="iq-waves-effect d-flex align-items-center">
                              <div class="caption">
                                 <h6 class="mb-0 line-height">Music Library</h6>
                              </div>
                           </router-link>
                        </li><li>
                           <router-link to="/tips" class="iq-waves-effect d-flex align-items-center">
                              <div class="caption">
                                 <h6 class="mb-0 line-height">Tips</h6>
                              </div>
                           </router-link>
                        </li>
                        <li class="pr-0">
                           <router-link to="/news-event" class="iq-waves-effect d-flex align-items-center">
                              <div class="caption">
                                 <h6 class="mb-0 line-height">News and Events</h6>
                              </div>
                           </router-link>
                        </li>
                        <li class="pr-0" v-if="userRole === 1">
                           <router-link to="/admincp" class="iq-waves-effect d-flex align-items-center">
                              <div class="caption">
                                 <h6 class="mb-0 line-height">Admin<i class="ri-arrow-right-s-line iq-arrow-right"></i></h6>
                              </div>
                           </router-link>
                        </li>
                </ul>
            </b-collapse>
        </nav>
      </div>
      </div>
    </div>
  <!-- TOP Nav Bar END -->
</template>
<script>
import SideBarItems from '../../../FackApi/json/SideBar'
import { mapGetters } from 'vuex'
export default {
  name: 'NavBarStyle1',
  props: {
    homeURL: { type: Object, default: () => ({ name: 'layout1.dashboard' }) },
    title: { type: String, default: 'Dashboard' },
    logo: { type: String, default: require('../../../assets/images/wfd-logo.svg') },
    horizontal: { type: Boolean, default: false },
    items: { type: Array }
  },
  async mounted () {
    document.addEventListener('click', this.closeSearch, true)
    this.userData = await JSON.parse(localStorage.getItem('user'));
    this.userRole = this.userData.role

  },
  computed: {
    ...mapGetters({
      bookmark: 'Setting/bookmarkState'
    })
  },
  data () {
    return {
      sidebar: SideBarItems,
      globalSearch: '',
      showSearch: false,
      showMenu: false,
      userData:null,
      userRole:0,
      userFriendRequest: [
        {
          img: require('../../../assets/images/user/05.jpg'),
          name: 'Jaques Amole',
          friend: '40  friends'
        },
        {
          img: require('../../../assets/images/user/06.jpg'),
          name: 'Lucy Tania',
          friend: '12  friends'
        },
        {
          img: require('../../../assets/images/user/07.jpg'),
          name: 'Val Adictorian',
          friend: '0  friends'
        },
        {
          img: require('../../../assets/images/user/08.jpg'),
          name: 'Manny Petty',
          friend: '3  friends'
        }

      ]
    }
  },
  methods: {
    miniSidebar () {
      this.$emit('toggle')
    },
    openSearch () {
      this.showSearch = true
      this.showMenu = 'iq-show'
      this.globalSearch = ''
    },
    closeSearch (event) {
      let classList = event.target.classList
      if (!classList.contains('searchbox') && !classList.contains('search-input')) {
        this.removeClass()
      }
    },
    removeClass () {
      this.showSearch = false
      this.showMenu = ''
      this.globalSearch = ''
    }
  }
}
</script>
